import React, { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { QueryGroup } from '@confluence/query-group';
import { TransparentErrorBoundary, Attribution } from '@confluence/error-boundary';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { PageTitle } from '@confluence/page-title';
import { SPAViewContext } from '@confluence/spa-view-context';

import { getSpaceNameFromResults, getSpaceNameQueryProps } from './utils';

const i18n = defineMessages({
	companyHubDefaultTitle: {
		id: 'document-title.document-space-title.company-hub.title',
		defaultMessage: 'Company hub',
		description: 'The default title for Company Hub in the absence of a set company hub name',
	},
});

type DocumentSpaceTitleProps = {
	spaceKey?: string;
	titlePrefix?: string;
};

export function DocumentSpaceTitle({ spaceKey, titlePrefix }: DocumentSpaceTitleProps) {
	const { siteTitle, companyHubName } = useContext(SPAViewContext);
	const isCompanyHub = isCompanyHubSpaceKey(spaceKey);
	const { formatMessage } = useIntl();

	//In the case when page is rendered by SSR, the __DOCUMENT_TITLE_TAG__ will be populated and we can skip document title.
	if (window['__DOCUMENT_TITLE_TAG__'] && window['__SSR_RENDERED__']) {
		return null;
	}
	return (
		<TransparentErrorBoundary attribution={Attribution.INHERIT}>
			<QueryGroup queryProps={getSpaceNameQueryProps(spaceKey)}>
				{({ results, loading }) => {
					if (loading) {
						return null;
					}
					const companyHubSpaceName = companyHubName || formatMessage(i18n.companyHubDefaultTitle);
					const spaceName = isCompanyHub ? companyHubSpaceName : getSpaceNameFromResults(results);
					const title = [titlePrefix, spaceName, siteTitle].filter((part) => part).join(' - ');
					// set for SSR document title
					(window as any).__DOCUMENT_TITLE_TAG__ = title;

					return <PageTitle title={title} />;
				}}
			</QueryGroup>
		</TransparentErrorBoundary>
	);
}
