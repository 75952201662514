import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	title: {
		id: 'team-selector.title',
		defaultMessage: 'Contributing teams',
		description: 'Title for listing all teams',
	},
});

export default messages;
