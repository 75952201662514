import React, { forwardRef } from 'react';

import { type PopupComponentProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';

const popupStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
});

const PopupComponent = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ 'data-testid': testId, ...props }, ref) => (
		<Box {...props} ref={ref} xcss={popupStyles} testId={testId} />
	),
);

export default PopupComponent;
