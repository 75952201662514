import { LoadableAfterPaint } from '@confluence/loadable';

export const HubPreviewFooter = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-HubPreviewFooter" */ '../src/hub-view/HubPreviewFooter'
			)
		).HubPreviewFooter,
});
