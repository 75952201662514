import {
	type AbortEvent,
	type ExperienceEvent,
	type FailEvent,
	isStop,
	type StopEvent,
} from '@atlassian/experience-tracker';

import { type ExperienceErrorAttributes, type ExperiencePerformanceAttributes } from './types';

export function isNetworkError(error: Error): boolean {
	return (
		error &&
		!!error.message &&
		// Firefox
		(error.message.includes('NetworkError') ||
			// Chrome
			error.message.startsWith('Failed to fetch') ||
			error.message.includes('GraphQL error: Failed to fetch') ||
			// cancel by users or unknown reason
			error.message.includes('GraphQL error: The operation was aborted') ||
			error.message.includes('GraphQL error: cancelled') ||
			error.message.includes('Abgebrochen') ||
			error.message.includes('отменено') ||
			error.message.includes('Сетевое соединение потеряно.') ||
			error.message.includes('anulowane') ||
			error.message.includes('annulé') ||
			error.message.includes('已取消') ||
			error.message.includes('cancelado') ||
			error.message.includes('キャンセルしました') ||
			error.message.includes('cancelled') ||
			error.message.includes('Network error') ||
			// GraphQL-wrapping network error
			!!((error as any)['graphQLErrors'] && (error as any)['networkError']))
	);
}

export function computeErrorAttributes(event: ExperienceEvent): ExperienceErrorAttributes {
	const attributes: ExperienceErrorAttributes = {};
	if (isFailEvent(event)) {
		attributes.failError = event.error;
	}

	if (isAbortEvent(event)) {
		attributes.abortReason = event.reason;
	}

	return attributes;
}

// compute sloSatisfied: true/false based on the latencySlo attribute
// for monitoring in SignalFx
export function computePerformanceAttributes(
	event: ExperienceEvent,
): ExperiencePerformanceAttributes {
	if (isStopEvent(event)) {
		const duration = event.duration;
		const latencySlo =
			event.attributes && hasLatencySLO(event.attributes) && event.attributes['latencySlo']!;
		if (typeof duration !== 'number' || typeof latencySlo !== 'number') {
			return {};
		}

		return {
			sloSatisfied: duration <= latencySlo,
			taskDuration: duration,
			latencySlo,
		};
	}
	return {};
}

function isStopEvent(e: ExperienceEvent): e is StopEvent {
	return isStop(e);
}

function isFailEvent(e: ExperienceEvent): e is FailEvent {
	return e.action === 'taskFail';
}

function isAbortEvent(e: ExperienceEvent): e is AbortEvent {
	return e.action === 'taskAbort';
}

function hasLatencySLO(attributes: object): attributes is object & { latencySlo: number } {
	return Object(attributes).hasOwnProperty('latencySlo');
}
