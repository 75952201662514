import { bindAll } from 'bind-event-listener';

import { type ExperienceEvent } from '@atlassian/experience-tracker';

import { TeamsExperienceTracker } from './teams-experience-tracker';
import {
	type CommonExperienceEventAttributes,
	type UserExperience,
	type UserExperiences,
} from './types';
import { computeErrorAttributes, computePerformanceAttributes, isNetworkError } from './utils';

type TrackerOptions = {
	isStandalone?: boolean;
	hasBrowseUsersPermission?: boolean;
	logErrorMessage?: (name: string, error: unknown) => void;
};

export function createTeamsExperienceTracker(
	fireExperienceEvent: <T extends UserExperience>(
		action: ExperienceEvent['action'],
		subject: T,
		attributes: UserExperiences[T] & CommonExperienceEventAttributes,
	) => void,
	options?: TrackerOptions,
): TeamsExperienceTracker {
	const tracker = new TeamsExperienceTracker({
		isNetworkOfflineError: isNetworkError,
	});

	tracker.subscribe((event) => {
		if (
			event.action === 'taskStart' ||
			event.action === 'taskSuccess' ||
			event.action === 'taskAbort' ||
			event.action === 'taskFail'
		) {
			const performanceAttributes = computePerformanceAttributes(event);
			const errorAttributes = computeErrorAttributes(event);
			const finalErrorDetails = Object.assign(
				errorAttributes,
				performanceAttributes,
				event.attributes,
				{
					// Because standalone app is deployed to prod quickly,
					// we will use this attribute to query in Splunk sooner.
					isStandalone: !!options?.isStandalone,
					hasBrowseUsersPermission: !!options?.hasBrowseUsersPermission,
				},
			);

			fireExperienceEvent(event.action, event.name as UserExperience, finalErrorDetails);

			if (event.action === 'taskFail' && options?.logErrorMessage) {
				options.logErrorMessage(`taskFail:${event.name}`, finalErrorDetails);
			}
		}
	});

	bindAll(window, [
		{
			type: 'unload',
			listener: () =>
				tracker.abort({
					reason: 'Abort due to Window unloading',
					attributes: {
						cause: 'WINDOW_UNLOAD',
					},
				}),
		},
		{
			type: 'pagehide',
			listener: () => {
				tracker.abort({
					reason: 'Window unloading',
					attributes: {
						cause: 'PAGE_HIDE',
					},
				});
			},
		},
	]);

	return tracker;
}
