import { ConfluenceSpaceAri, IdentityTeamAri, JiraProjectAri } from '@atlassian/ari';

import { logErrorMessage } from '../sentry';

export const getTeamId = (teamAri: string) => {
	let teamId: string | undefined;

	const isIdentityTeamsAri = IdentityTeamAri.check(teamAri);

	if (isIdentityTeamsAri) {
		const identityTeamId = IdentityTeamAri.parse(teamAri).teamId;
		if (identityTeamId !== 'unidentified') {
			teamId = identityTeamId;
		} else {
			logErrorMessage('Team ID has value "unidentified" in getTeamId', { teamAri });
		}
	} else {
		logErrorMessage('Cannot parse IdentityTeamARI in getTeamId', { teamAri });
	}

	return teamId;
};

export const getTeamProfileUrl = (containerAri: string, teamAri: string) => {
	let teamProfileUrl: string | undefined;
	const productPath = ConfluenceSpaceAri.check(containerAri)
		? 'wiki'
		: JiraProjectAri.check(containerAri)
			? 'jira'
			: undefined;

	let teamId: string | undefined;
	teamId = getTeamId(teamAri);

	if (productPath && teamId) {
		teamProfileUrl = `${window.location.origin}/${productPath}/people/team/${teamId}`;
	} else {
		logErrorMessage('Cannot create teamProfileUrl in getTeamProfileUrl', { containerAri, teamAri });
	}

	return teamProfileUrl;
};
