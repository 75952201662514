import React, { type SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { useIntl } from 'react-intl-next';
import type { EntryPointProps } from 'react-relay';

import { type UIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';
import TeamsIcon from '@atlaskit/icon/core/teams';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, Pressable, xcss } from '@atlaskit/primitives';
import { useConnectedTeams } from '@atlaskit/teams-public';
import { usePeopleTeamAnalyticsEvents } from '@atlassian/people-teams-ui/analytics';

import PopupComponent from '../../common/no-background-popup-component';
import { TeamSelector } from '../team-selector';

import messages from './messages';

type TeamButtonProps = {
	containerId: string; // TODO: ARI type?
	Wrapper?: React.ElementType;
};

const buttonStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.100',
	paddingInline: 'space.075',
	display: 'flex',
	alignItems: 'center',
	height: '32px',
	color: 'color.text.subtle',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	':active': {
		backgroundColor: 'color.background.neutral.subtle.pressed',
	},
});

export const TeamButton = ({ containerId, Wrapper = React.Fragment }: TeamButtonProps) => {
	const {
		numberOfTeams,
		teams,
		isLoading,
		hasLoaded,
		containerId: currentContainerId,
		fetchNumberOfConnectedTeams,
		fetchConnectedTeams,
	} = useConnectedTeams();
	// NOTE: Later add loading state when button shows always
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();
	const { fireEvent } = usePeopleTeamAnalyticsEvents();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		// if containerId is changed, we need to fetch the teams again
		if (currentContainerId === undefined || containerId !== currentContainerId) {
			fetchNumberOfConnectedTeams(containerId);
		}
	}, [containerId, fetchNumberOfConnectedTeams, currentContainerId]);

	useEffect(() => {
		if (containerId === currentContainerId && numberOfTeams !== undefined && numberOfTeams > 0) {
			const result = createAnalyticsEvent({})?.context.find(
				(item) => item.attributes && item.attributes.isMultiBoard !== undefined,
			);
			fireEvent('ui.teamButton.viewed', {
				teamsCount: numberOfTeams ?? 0,
				isMultiBoard: result?.attributes?.isMultiBoard,
			});
		}
	}, [containerId, createAnalyticsEvent, currentContainerId, fireEvent, numberOfTeams]);

	const onClick = useCallback(
		(_: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			setIsOpen(true);

			const result = analyticsEvent?.context.find(
				(item) => item.attributes && item.attributes.isMultiBoard !== undefined,
			);
			fireEvent('ui.teamButton.clicked', {
				teamsCount: numberOfTeams ?? 0,
				isMultiBoard: result?.attributes?.isMultiBoard,
			});

			if (!teams && !isLoading && !hasLoaded) {
				fetchConnectedTeams(containerId);
			}
		},
		[containerId, fetchConnectedTeams, isLoading, teams, hasLoaded, fireEvent, numberOfTeams],
	);

	if (containerId !== currentContainerId || numberOfTeams === undefined || numberOfTeams === 0) {
		return null;
	}

	return (
		<Popup
			isOpen={isOpen}
			testId="team-button-popup"
			onClose={() => setIsOpen(false)}
			content={() => <TeamSelector containerId={containerId} teams={teams} />}
			trigger={(triggerProps: TriggerProps) => (
				<Wrapper>
					<Pressable
						{...triggerProps}
						onClick={onClick}
						xcss={buttonStyles}
						testId="team-button-test-id"
					>
						<TeamsIcon color="currentColor" label={formatMessage(messages.iconLabel)} />
						{numberOfTeams > 1 && <Box padding="space.050">{numberOfTeams}</Box>}
					</Pressable>
				</Wrapper>
			)}
			placement="bottom-start"
			strategy="absolute"
			popupComponent={PopupComponent}
		/>
	);
};

export const TeamButtonAsEntrypoint = ({
	props,
}: EntryPointProps<
	Record<string, never>,
	Record<string, never>,
	TeamButtonProps,
	Record<string, never>
>) => <TeamButton {...props} />;
