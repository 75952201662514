import { createStore, type StoreActionApi } from 'react-sweet-state';

type State = {
	// We want `cloudId` is always a string and empty value as default value. That helps to avoid dealing with null/undefined hassle.
	cloudId: string;
};
type StoreApi = StoreActionApi<State>;

const actions = {
	// This action is used by EmbeddedDirectory to get Cloud Id in a remote store.
	setCloudId:
		(cloudId: string) =>
		({ setState }: StoreApi) => {
			setState({
				cloudId,
			});
		},
};
type Actions = typeof actions;

const initialState: State = {
	cloudId: '',
};

/**
 * Manage states of a site
 */
export const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'Site',
});
