/* eslint-disable check-file/filename-blocklist */
import { SpaceNameQuery } from './SpaceNameQuery.graphql';

export const getSpaceNameFromResults = (results) => {
	const errors = results.filter((result) => result.error).map((result) => result.error);
	if (errors.length) {
		throw new Error(errors.join('\n\n'));
	}
	return results[0]?.data?.space?.name || '';
};

export const getSpaceNameQueryProps = (spaceKey) => {
	return [
		{
			query: SpaceNameQuery,
			variables: { spaceKey },
			skip: !spaceKey,
		},
	];
};
