import gql from 'graphql-tag';

export const SpaceNameQuery = gql`
	query SpaceNameQuery($spaceKey: String!) {
		space(key: $spaceKey) {
			id
			name
			key
		}
	}
`;
