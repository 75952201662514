import {
	type CreateErrorHandler,
	createErrorHandlerWithPackageContext,
	logErrorMessageWithPackageContext,
	logExceptionWithPackageContext,
	logInfoMessageWithPackageContext,
} from '@atlassian/teams-client/sentry';

const packageContext = {
	packageName: process.env._PACKAGE_NAME_ as string,
	packageVersion: process.env._PACKAGE_VERSION_ as string,
};

export const logException = logExceptionWithPackageContext(packageContext);
export const logErrorMessage = logErrorMessageWithPackageContext(packageContext);
export const logInfoMessage = logInfoMessageWithPackageContext(packageContext);
export const createErrorHandler: CreateErrorHandler =
	createErrorHandlerWithPackageContext(packageContext);
