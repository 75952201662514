import { createHook, createStore } from 'react-sweet-state';

// clients
import { teamsClient, userInfoProvider } from '@atlassian/teams-client';

import { type State, type StoreApi } from './types';

// Private actions:
// It is called by `getPrincipalUserInfo` action
const getIsSiteAdmin =
	(cloudId?: string) =>
	async ({ setState }: StoreApi) => {
		if (!cloudId) {
			setState({
				isSiteAdmin: false,
			});
			return;
		}

		let isSiteAdmin = false;

		try {
			isSiteAdmin = await teamsClient.getIsSiteAdmin(cloudId);
		} catch (e) {
			// do nothing because isSiteAdmin is returned as false
		} finally {
			setState({
				isSiteAdmin,
			});
		}
	};

// it is called by `getPrincipalUserInfo` action
const getMutability =
	(userId: string) =>
	async ({ setState }: StoreApi) => {
		try {
			const data = await teamsClient.getProfileWithMutability(userId);
			setState({
				mutabilityConstraints: data,
			});
		} catch (e) {
			// do nothing because isSiteAdmin is returned as false
		}
	};

const getAvatarUploadedStatus =
	(userId: string) =>
	async ({ setState }: StoreApi) => {
		try {
			setState({
				avatarUploaded: {
					isLoading: true,
					error: undefined,
					uploaded: undefined,
				},
			});
			const data = await teamsClient.getMyAvatarUploadedStatus(userId);
			setState({
				avatarUploaded: {
					isLoading: false,
					uploaded: data.uploaded,
				},
			});
		} catch (e) {
			setState({
				avatarUploaded: {
					isLoading: false,
					error: e instanceof Error ? e : new Error('Unknown error'),
					uploaded: undefined,
				},
			});
		}
	};

const actions = {
	/**
	 * Get user information of current logged-in user.
	 * @param cloudId: If Cloud Id is passed, that means People Directory is rendered in a site and we use Cloud Id to check 'isSiteAdmin' info.
	 * If Cloud Id is not passed, `isSiteAdmin` is false by default.
	 */
	getPrincipalUserInfo:
		(cloudId?: string) =>
		async ({ setState, getState, dispatch }: StoreApi) => {
			// stop fetching when it is already fetched
			if (getState().isFetching || getState().hasLoaded) {
				return;
			}

			setState({
				isFetching: true,
				hasLoaded: false,
			});

			try {
				const userBasicData = await userInfoProvider.get();
				setState({
					principalUserId: userBasicData.userId,
					...userBasicData,
				});

				void dispatch(getIsSiteAdmin(cloudId));
				void dispatch(getMutability(userBasicData.userId));

				setState({
					hasLoaded: true,
				});
			} catch (e) {
				setState({
					hasError: true,
					hasLoaded: false,
				});
			} finally {
				setState({
					isFetching: false,
				});
			}
		},
	getAvatarUploadedStatus,
	setAvatarUploadedStatus:
		(uploaded: boolean) =>
		({ setState }: StoreApi) => {
			setState({
				avatarUploaded: {
					uploaded,
					isLoading: false,
					isDeleting: false,
				},
			});
		},
	deleteUserAvatar:
		() =>
		async ({ getState, dispatch, setState }: StoreApi) => {
			try {
				setState({
					avatarUrl: undefined,
					avatarUploaded: {
						isDeleting: true,
						isLoading: true,
						error: undefined,
					},
				});
				await teamsClient.deleteAvatar(getState().principalUserId);

				dispatch(actions.fetchAvatarInfo());
			} catch (e) {
				setState({
					avatarUploaded: {
						isLoading: false,
						isDeleting: false,
						error: e instanceof Error ? e : new Error('Unknown error'),
					},
				});
			} finally {
				setState({
					avatarUploaded: {
						isLoading: false,
						isDeleting: false,
					},
				});
			}
		},
	fetchAvatarInfo:
		() =>
		async ({ getState, setState }: StoreApi) => {
			const avatarInfo = await teamsClient.fetchUserAvatarInfo(getState().principalUserId);
			setState({
				avatarUrl: avatarInfo.url,
				avatarUploaded: {
					uploaded: avatarInfo.uploaded,
					isLoading: false,
				},
			});
		},
	updateUserAvatar:
		(userId: string, fileURI: string) =>
		async ({ setState }: StoreApi) => {
			setState({
				avatarUrl: undefined,
				avatarUploaded: {
					isDeleting: false,
					isLoading: true,
					isUploading: true,
					hasUploadedAvatar: false,
					error: undefined,
				},
			});

			try {
				const avatarUrl = await teamsClient.updateUserAvatar({
					id: userId,
					fileURI,
				});

				setState({
					avatarUrl,
					avatarUploaded: {
						uploaded: true,
						isLoading: false,
						isUploading: false,
						hasUploadedAvatar: true,
					},
				});
			} catch (error) {
				setState({
					avatarUploaded: {
						isLoading: false,
						isUploading: false,
						error: error instanceof Error ? error : new Error('Unknown error'),
						hasUploadedAvatar: undefined,
					},
				});
			}
		},
	setPrincipalUserInfo:
		(data: Partial<Pick<State, 'avatarUrl'>>) =>
		({ setState }: StoreApi) => {
			setState(data);
		},
};

type Actions = typeof actions;

const initialState: State = {
	principalUserId: '',
	avatarUrl: '',
	email: '',
	fullName: '',
	title: '',
	isSiteAdmin: false,
	isFetching: false,
	hasError: false,
	hasLoaded: false,
	avatarUploaded: {
		isLoading: false,
	},
};

/**
 * Manage states of a site
 */
const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'PrincipalUser',
});

export const usePrincipalUser = createHook(Store);

export const useAvatarUploadedStatus = createHook(Store, {
	selector: (state) => state.avatarUploaded,
});
