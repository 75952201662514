export enum StatusCode {
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	TIMEOUT = 408,
	GONE = 410,
	PAYLOAD_TOO_LARGE = 413,
}
export function isAuthError(error?: Error) {
	return (
		isErrorStatusCode(StatusCode.UNAUTHORIZED, error) ||
		isErrorStatusCode(StatusCode.FORBIDDEN, error)
	);
}
export function isErrorStatusCode(statusCode: number, error?: Error): boolean {
	if (!error) {
		return false;
	}
	if ((error as any)['status'] === statusCode || (error as any)['statusCode'] === statusCode) {
		return true;
	}
	const reg = new RegExp(`status (code)?.*(${statusCode})`);
	return !!error?.message?.match(reg);
}
