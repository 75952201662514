import React, { useEffect, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@compiled/react';

import AkButton from '@atlaskit/button/custom-theme-button';
import type { Appearance } from '@atlaskit/button/types';

import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';
import { useSubmitSSRScriptErrors } from '@confluence/ssr-scripts-utils';

import { spaceStarI18n } from './SpaceStarI18n';
import type { SpaceStarProps } from './SpaceStar';
import { SpaceStar } from './SpaceStar';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledAkButton = styled(AkButton)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		marginLeft: '15px',
	},
});

type AkButtonProps = {
	appearance?: Appearance | undefined;
	isDisabled?: boolean;
	dataTestId?: string;
};

type SpaceStarButtonProps = AkButtonProps & SpaceStarProps;

/**
 * Regular Atlaskit text button that allows users to star or unstar a space.
 *
 * Renders as a button with literal text like "Star this space". Used on space overview.
 */
export const SpaceStarTextButton: React.FC<SpaceStarButtonProps> = (props) => {
	return (
		<SpaceStar {...props}>
			{({ toggle }) => <SpaceStarButtonInner toggle={toggle} {...props} />}
		</SpaceStar>
	);
};

const SpaceStarButtonInner: React.FC<SpaceStarButtonProps & { toggle?: (e: any) => void }> = ({
	toggle: toggleStar,
	dataTestId,
	isStarred,
	isDisabled,
	appearance,
}) => {
	useSubmitSSRScriptErrors('space-star-text-button');

	useEffect(() => {
		if (window?.__SSR_RENDERED__) {
			const event = window?.__SSR_EVENTS_CAPTURE__?.spaceStarTextButton;
			if (event) {
				toggleStar!(event);
				delete window?.__SSR_EVENTS_CAPTURE__?.spaceStarTextButton;
			}
		}
	}, [toggleStar]);

	return (
		<Fragment>
			<SSRActionLoadingSpinner
				spinnerId="space-star-text-loading-spinner"
				actionType="spaceStarTextButton"
			/>
			<StyledAkButton
				data-test-id={dataTestId || (isStarred ? 'unstar-space-ak-button' : 'star-space-ak-button')}
				appearance={appearance}
				isDisabled={isDisabled}
				onClick={toggleStar}
			>
				<FormattedMessage {...spaceStarI18n[isStarred ? 'unstar' : 'star']} />
			</StyledAkButton>
		</Fragment>
	);
};
