import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl-next';

import Badge from '@atlaskit/badge';
import Heading from '@atlaskit/heading';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import { ButtonItem, HeadingItem, MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { TeamProfileCardWithContainer } from '@atlaskit/profilecard';
import TeamAvatar from '@atlaskit/teams-avatar';
import { usePeopleTeamAnalyticsEvents } from '@atlassian/people-teams-ui/analytics';
import type { TeamWithMemberships } from '@atlassian/teams-client/types';

import PopupComponent from '../../common/no-background-popup-component';
import { getTeamProfileUrl } from '../../common/utils/ari';
import { usePrincipalUser } from '../../controllers/principal-user';
import { useSite } from '../../controllers/site/hooks';

import messages from './messages';
import TeamSelectorSkeleton from './team-selector-skeleton';

type TeamSelectorProps = {
	containerId: string;
	teams?: TeamWithMemberships[];
};

type TeamItemProps = {
	containerId: string;
	team: TeamWithMemberships;
};

const teamCardWrapperStyles = xcss({
	backgroundColor: 'elevation.surface.overlay',
	borderRadius: 'border.radius.200',
	boxShadow: 'elevation.shadow.overlay',
});

const containerStyles = xcss({
	backgroundColor: 'elevation.surface.overlay',
	borderRadius: 'border.radius',
	boxShadow: 'elevation.shadow.overlay',
});

const TeamCard = ({ team, containerId }: TeamItemProps) => {
	const [{ cloudId }] = useSite();
	const [{ principalUserId }] = usePrincipalUser();
	const { fireEvent } = usePeopleTeamAnalyticsEvents();

	useEffect(() => {
		fireEvent('ui.teamProfileCard.viewed', { screen: 'RECENTLY_VIEWED' });
	}, [fireEvent]);

	return (
		<Box xcss={teamCardWrapperStyles} testId="team-profile-card">
			<TeamProfileCardWithContainer
				containerId={containerId}
				teamId={team.id}
				displayName={team.displayName}
				description={team.description}
				avatarImageUrl={team.smallAvatarImageUrl}
				headerImageUrl={team.smallHeaderImageUrl}
				memberAvatars={team.members.map((member) => ({
					src: member.avatarUrl,
					name: member.fullName,
					key: member.id,
				}))}
				memberCount={team.memberCount}
				userId={principalUserId}
				cloudId={cloudId}
				isVerified={team.isVerified}
				teamProfileUrl={getTeamProfileUrl(containerId, team.id)}
			/>
		</Box>
	);
};

const TeamItem = ({ team, containerId }: TeamItemProps) => {
	const [showTeamProfile, setShowTeamProfile] = useState(false);
	const { fireEvent } = usePeopleTeamAnalyticsEvents();

	return (
		<Popup
			isOpen={showTeamProfile}
			onClose={() => setShowTeamProfile(false)}
			content={() => <TeamCard team={team} containerId={containerId} />}
			trigger={(triggerProps) => (
				<ButtonItem
					{...triggerProps}
					isSelected={showTeamProfile}
					onClick={() => {
						fireEvent('ui.teamProfileButton.clicked', {});
						setShowTeamProfile(!showTeamProfile);
					}}
					iconBefore={<TeamAvatar size="small" src={team.smallAvatarImageUrl} />}
					iconAfter={<ChevronRightIcon label="dropdown-chevron" spacing="spacious" />}
				>
					{team.displayName}
				</ButtonItem>
			)}
			placement="right-start"
			shouldRenderToParent={false}
			popupComponent={PopupComponent}
		/>
	);
};

export const TeamSelector = ({ teams, containerId }: TeamSelectorProps) => {
	const { formatMessage } = useIntl();

	if (!teams) {
		return <TeamSelectorSkeleton />;
	}

	if (teams.length === 1) {
		return <TeamCard team={teams[0]} containerId={containerId} />;
	}

	return (
		<Box xcss={containerStyles}>
			<MenuGroup minWidth={320} maxWidth={400}>
				<Section>
					<HeadingItem>
						<Inline spread="space-between" alignBlock="center">
							<Heading size="xxsmall">{formatMessage(messages.title)}</Heading>
							<Badge>{teams.length}</Badge>
						</Inline>
					</HeadingItem>
					{teams.map((team) => (
						<TeamItem team={team} containerId={containerId} key={team.id} />
					))}
				</Section>
			</MenuGroup>
		</Box>
	);
};
