import { ExperienceTracker } from '@atlassian/experience-tracker';

import { type UserExperience } from '../types';

import { isAuthError, isErrorStatusCode, StatusCode } from './utils';

type OptionsType = ConstructorParameters<typeof ExperienceTracker>[0];

export class TeamsExperienceTracker extends ExperienceTracker {
	// error names here will also be sent in the attribute `cause`
	private _ignoredErrors = ['SentryIgnoreError'];

	_errorNameInIgnoredList(error: Error): string | undefined {
		const name = error && error.constructor.name;
		return this._ignoredErrors.includes(name) ? name : undefined;
	}

	constructor(options: OptionsType) {
		super(options);
	}

	stopOnError({ name, error }: { name: UserExperience; error: Error }) {
		if (isAuthError(error)) {
			this.abort({
				name,
				reason: 'Abort due to user unauthenticated',
				attributes: { cause: 'USER_UNAUTHENTICATED', error },
				checkForTimeout: false,
			});
			return;
		}

		if (isErrorStatusCode(StatusCode.GONE, error)) {
			this.abort({
				name,
				reason: 'Abort due to unavailable resource',
				attributes: { cause: 'GONE', error },
				checkForTimeout: false,
			});
			return;
		}

		const ignoredErrorName = this._errorNameInIgnoredList(error);
		if (ignoredErrorName) {
			this.abort({
				name,
				reason: 'Abort due to an error in ignore list',
				attributes: { cause: ignoredErrorName },
				checkForTimeout: false,
			});
			return;
		}

		super.stopOnError({ name, error });
	}
}
