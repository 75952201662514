import gql from 'graphql-tag';

export const AudienceSegmentationContextProviderQuery = gql`
	query AudienceSegmentationContextProviderQuery($accountId: ID) {
		user(id: $accountId) {
			id
			confluence {
				groupsWithId {
					id
				}
			}
		}
	}
`;
