import type { FC, ReactNode } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import type { EmojiDescription } from '@atlaskit/emoji/types';
import { token } from '@atlaskit/tokens';
import { Box, xcss, Inline } from '@atlaskit/primitives';

import { ConfluenceSpaceAri } from '@atlassian/ari';
import { TeamButton } from '@atlassian/teams';

import { DocumentSpaceTitle } from '@confluence/document-title';
import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import {
	EMOJI_SIZE_MEDIUM,
	EMOJI_SIZE_LARGE,
} from '@confluence/emoji-title/entry-points/emojiTitleConstants';
import { Breadcrumbs, FixedSpaceHeaderContainer } from '@confluence/breadcrumbs';
import { useRouteDataRef } from '@confluence/route-manager';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { ContentClassificationTag } from '@confluence/data-classification';
import { ContentAppearanceType } from '@confluence/content-appearance';
import {
	useContentClassification,
	useDefaultAndSpaceClassificationLevels,
} from '@confluence/data-classification/entry-points/queries';
import { useCreateSpaceExperiment } from '@confluence/create-space/entry-points/createSpace';
import { COVER_IMAGE_FIXED_WIDTH } from '@confluence/page-cover-picture';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceTitleContainer = styled.div<{
	contentAppearance: any;
	isLeftAlignedTitleFullWidthPageWithFixedWidthImageAndEmoji?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ contentAppearance, isLeftAlignedTitleFullWidthPageWithFixedWidthImageAndEmoji }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		contentAppearance !== ContentAppearanceType.DEFAULT
			? isLeftAlignedTitleFullWidthPageWithFixedWidthImageAndEmoji
				? { display: 'flex' }
				: {}
			: {
					margin: `0 auto ${token('space.300')}`,
					wordBreak: 'break-word',
					wordWrap: 'break-word',
					maxWidth: '760px',
				},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpaceHeaderText = styled.h1<{
	isTitleCenterAligned?: boolean;
	hasCoverPicture?: boolean;
	hasIcon?: boolean;
	spaceIconPath?: string;
	isObjectHeaderEnabled?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	alignItems: ({ hasCoverPicture, isTitleCenterAligned, hasIcon }) =>
		hasIcon && !isTitleCenterAligned && !hasCoverPicture ? 'center' : 'initial',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ hasCoverPicture, hasIcon, isObjectHeaderEnabled }) =>
		isObjectHeaderEnabled
			? hasCoverPicture && hasIcon
				? '-40px !important'
				: '72px !important'
			: hasCoverPicture && hasIcon
				? '-56px !important'
				: 'initial',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: ({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'flex' : 'inline-flex'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ isTitleCenterAligned, hasCoverPicture }) =>
		isTitleCenterAligned || hasCoverPicture ? 'column' : 'initial',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: ({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'center' : 'intial'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	textAlign: ({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'center' : 'initial'),
	flex: 1,
	wordBreak: 'break-word',
	wordWrap: 'break-word',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: ({ isTitleCenterAligned }) =>
		isTitleCenterAligned ? 'initial' : token('space.250'),
	font: token('font.heading.xlarge'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ hasCoverPicture, isTitleCenterAligned, isObjectHeaderEnabled }) => ({
		gap:
			hasCoverPicture && !isObjectHeaderEnabled
				? token('space.300')
				: hasCoverPicture && isObjectHeaderEnabled
					? token('space.600')
					: isTitleCenterAligned // NOSONAR - SonarQube complains about nested ternary operator, but fixing it might break the dynamic styling in Compiled (known bug there)
						? token('space.200')
						: token('space.150'),
	}),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiWrapper = styled.span({
	display: 'inline-flex',
	flex: 0,
	marginRight: token('space.075'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '1px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBottom: '1px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmojiWrapperWithAlignment = styled(EmojiWrapper)({
	marginRight: token('space.0'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconStyleWrapper = styled.span<{
	isTitleCenterAligned?: boolean;
	isFixedWidthImageOption?: boolean;
	isPageContentFullWidth?: boolean;
	hasCoverPicture?: boolean;
	isCompanyHub?: boolean;
}>({
	display: 'flex',
	alignItems: 'initial',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: ({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'center' : 'initial'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ isFixedWidthImageOption, hasCoverPicture, isTitleCenterAligned }) =>
		isFixedWidthImageOption && hasCoverPicture && !isTitleCenterAligned
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${COVER_IMAGE_FIXED_WIDTH}px`
			: 'unset',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ isFixedWidthImageOption, hasCoverPicture, isTitleCenterAligned }) =>
		isFixedWidthImageOption && hasCoverPicture && !isTitleCenterAligned ? '0 auto' : 'unset',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginTop: ({ isCompanyHub, hasCoverPicture }) =>
		!hasCoverPicture && isCompanyHub ? token('space.150') : 'unset',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: ({
		isFixedWidthImageOption,
		hasCoverPicture,
		isTitleCenterAligned,
		isPageContentFullWidth,
	}) =>
		isFixedWidthImageOption && hasCoverPicture && !isTitleCenterAligned && isPageContentFullWidth
			? token('space.800')
			: 'unset',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceIcon = styled.img({
	borderRadius: '8px',
});

const BREADCRUMBS_MIN_WIDTH = '150px';

const breadcrumbsWrapper = xcss({
	minWidth: BREADCRUMBS_MIN_WIDTH, // needed for responsive breadcrumbs
});

const noWrapStyles = xcss({
	display: 'flex',
	flexWrap: 'nowrap',
	gap: 'space.050',
	alignItems: 'center',
});

const titleInlineStyles = xcss({
	display: 'inline-flex',
	alignItems: 'center',
	gap: 'space.100',
});

const titleCenterAlignedInlineStyles = xcss({
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: 'space.100',
});

interface SpaceHeaderProps {
	children?: ReactNode;
	spaceKey: string;
	isThemed?: boolean;
	spaceIconPath?: string;
	contentId?: string;
}

type SpaceHeaderTextProps = {
	title: ReactNode | string;
	emoji: string | EmojiDescription | null;
	isTitleCenterAligned?: boolean;
	contentAppearance?: string;
	hasCoverPicture?: boolean;
	spaceIconPath?: string;
	isCompanyHub?: boolean;
	isLeftAlignedTitleFullWidthPageWithFixedWidthImageAndEmoji?: boolean;
	isPageContentFullWidth?: boolean;
	isFixedWidthImageOption?: boolean;
	cloudId?: string;
	spaceId?: string;
};

export const SpaceHeading = ({
	emoji,
	title,
	isTitleCenterAligned,
	isCompanyHub,
	contentAppearance,
	hasCoverPicture,
	spaceIconPath,
	isLeftAlignedTitleFullWidthPageWithFixedWidthImageAndEmoji,
	isPageContentFullWidth,
	isFixedWidthImageOption,
	cloudId,
	spaceId,
}: SpaceHeaderTextProps) => {
	const emojiHeight = hasCoverPicture ? EMOJI_SIZE_LARGE : EMOJI_SIZE_MEDIUM;

	const hasEmoji = Boolean(emoji);

	const { isSpaceTypesCreationFlowEnabled } = useCreateSpaceExperiment();
	const isSpaceIconEnabled = isSpaceTypesCreationFlowEnabled && !!spaceIconPath;

	// eslint-disable-next-line confluence-feature-gating/unsafe-no-exposure
	const [teamAndContainerM1ExpConfig] = UNSAFE_noExposureExp('team_and_container_m1');

	return (
		<SpaceTitleContainer
			contentAppearance={contentAppearance}
			isLeftAlignedTitleFullWidthPageWithFixedWidthImageAndEmoji={
				isLeftAlignedTitleFullWidthPageWithFixedWidthImageAndEmoji
			}
		>
			<SpaceHeaderText
				isTitleCenterAligned={isTitleCenterAligned}
				hasCoverPicture={hasCoverPicture}
				hasIcon={hasEmoji || isSpaceIconEnabled}
				data-test-id="title-text"
				id="title-text"
				spaceIconPath={spaceIconPath}
				isObjectHeaderEnabled={fg('confluence_frontend_object_header')}
			>
				{hasEmoji || isSpaceIconEnabled ? (
					<IconStyleWrapper
						isTitleCenterAligned={isTitleCenterAligned}
						isFixedWidthImageOption={isFixedWidthImageOption}
						isPageContentFullWidth={isPageContentFullWidth}
						isCompanyHub={isCompanyHub}
						hasCoverPicture={hasCoverPicture}
						data-testid="emoji"
					>
						{hasEmoji ? (
							<LazyEmojiComponentLoader
								emoji={emoji}
								context="spaceHeader"
								// TODO: update this to use size constants
								height={emojiHeight}
								wrapper={EmojiWrapperWithAlignment}
								renderResourcedEmoji
								isPageTitleComponent
							/>
						) : (
							<SpaceIcon
								data-vc="space-header-icon"
								width={emojiHeight}
								height={emojiHeight}
								src={`${CONTEXT_PATH}${spaceIconPath}`}
							/>
						)}
					</IconStyleWrapper>
				) : null}
				{teamAndContainerM1ExpConfig.get('isEnabled', false) &&
				fg('team-connection-visibility-in-confluence') ? (
					<Inline xcss={isTitleCenterAligned ? titleCenterAlignedInlineStyles : titleInlineStyles}>
						{title}
						{cloudId && spaceId && !isCompanyHub && (
							<Box testId="team-button-container-id">
								<TeamButton
									containerId={ConfluenceSpaceAri.create({
										siteId: cloudId,
										spaceId,
									}).toString()}
								/>
							</Box>
						)}
					</Inline>
				) : (
					title
				)}
			</SpaceHeaderText>
		</SpaceTitleContainer>
	);
};

export const SpaceHeader: FC<SpaceHeaderProps> = ({ children, spaceKey, isThemed, contentId }) => {
	const contentClassificationMetadata = useContentClassification({
		contentId,
		spaceKeyOverride: spaceKey,
		contentStatusContext: 'current-only',
	});

	const spaceClassificationMetadata = useDefaultAndSpaceClassificationLevels(spaceKey);

	const routeDataRef = useRouteDataRef();
	// We are expanding atl.general panel to full width when the side nav is not present. This is the case for
	// company hub and when url has mode=global, such as on custom homepage
	// TODO: For Nav 4, we want to replace these checks so that we simply check for existence of the side nav (DISCO-2142)
	const isFullWidth = routeDataRef.current?.queryParams?.mode === 'global';

	return (
		<FixedSpaceHeaderContainer
			isThemed={isThemed}
			isFullWidth={isFullWidth}
			isModernizedBreadcrumbsEnabled={fg('confluence_modernized_breadcrumbs')}
		>
			<DocumentSpaceTitle spaceKey={spaceKey} />
			<Box
				xcss={[breadcrumbsWrapper, fg('confluence_modernized_breadcrumbs') && noWrapStyles]}
				testId="breadcrumbs-and-classification-tag"
			>
				<Breadcrumbs spaceKey={spaceKey} />
				<div data-testid="content-classification-tag">
					<ContentClassificationTag
						contentId={contentId ?? ''}
						contentType="page"
						customSitesTitleComponent
						contentClassificationMetadata={contentClassificationMetadata}
						spaceClassificationMetadata={spaceClassificationMetadata}
						isEditMode={false}
					/>
				</div>
			</Box>

			{children}
		</FixedSpaceHeaderContainer>
	);
};
