import { useCallback, useMemo } from 'react';

import { usePeopleTeamsAnalyticsSubcontext } from '@atlaskit/people-teams-ui-public/analytics';
import { type ExperienceEvent } from '@atlassian/experience-tracker';

import {
	createTeamsExperienceTracker,
	type UserExperience,
	type UserExperiences,
} from './experiences';
import { type CommonExperienceEventAttributes } from './experiences/types';
import { type EventKey } from './generated/analytics.types';
import { useAnalyticsEvents } from './generated/use-analytics-events';
import { useAnalyticsEventsWithTags } from './use-analytics-events-with-tags';

export const usePeopleTeamAnalyticsEvents = () => {
	const [{ eventAttributes: injectedEventAttributes }, { setEventAttributes }] =
		usePeopleTeamsAnalyticsSubcontext();
	const { fireEvent } = useAnalyticsEvents();

	const fireEventWithSubcontextAttributesInjected = useCallback(
		<K extends EventKey>(...params: Parameters<typeof fireEvent<K>>) => {
			const [event, attributes] = params;
			const eventWithSubcontextAttributes = {
				...attributes,
				...injectedEventAttributes,
			};
			// @ts-ignore allow injecting subcontext attributes
			fireEvent(event, eventWithSubcontextAttributes);
		},
		[fireEvent, injectedEventAttributes],
	);

	return {
		fireEvent: fireEventWithSubcontextAttributesInjected,
		setInjectedEventAttributes: setEventAttributes,
	};
};

export const usePeopleTeamAnalyticsEventsWithTags = () => useAnalyticsEventsWithTags();

export const useTeamsExperienceTracker = () => {
	const { fireEvent } = usePeopleTeamAnalyticsEvents();

	const fireExperienceEvent = useCallback(
		<T extends UserExperience>(
			action: ExperienceEvent['action'],
			subject: T,
			attributes: UserExperiences[T] & CommonExperienceEventAttributes,
		) => {
			// @ts-ignore TODO: we may need to declare the keys to a const in the types file so we can do a check here
			fireEvent(`operational.${subject.replace(/[\/-]/g, '_')}.${action}`, {
				...attributes,
				source: 'clientMetrics',
			});
		},
		[fireEvent],
	);

	const experienceTracker = useMemo(
		() => createTeamsExperienceTracker(fireExperienceEvent),
		[fireExperienceEvent],
	);

	return experienceTracker;
};
